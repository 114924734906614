import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import { get, includes } from 'lodash';
import $ from 'jquery';

import {
  toggleAll,
  initServiceWorker,
  setShowSubscribeContainer,
  getOverScaleBanner,
  getTransparentNav,
  isPageLoading
} from '../../actions/common';

import AppRouter from './routes';

import Navbar from '../Nav';
import FooterLayout from '../Footer';

// import './fonts/font.css';
import './global.css';
import AppHolder from './commonStyle';
import themes from '../../settings/themes';

import { themeConfig } from '../../settings';
import Loading from '../../components/Loading';

window.$ = $;
window.jQuery = $;

const pagesHaveSubscribe = [
  'overview',
  'press-release',
  // 'annual-report',
  // 'quarterly-fillings',
  'our-products',
  'about',
  'disclaimers',
  'privacy-policy',
  'legal-notice',
  'all-press-release'
];

const whiteColorSubscribe = [
  '/press-release'
];

const pageWithoutHoc = [
  '/unsubscribe'
];

const isComing = process.env.REACT_APP_COMING_SOON === '1';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOver: false
    };
  }

  componentDidMount() {
    const { setShowSubscribeContainer, location } = this.props;

    const pathname = get(location, 'pathname');
    const showSubscribe = this.checkPageHaveSubscribe(pathname);

    setShowSubscribeContainer(showSubscribe);
  }

  checkPageHaveSubscribe = (pathname) => {
    // For home page
    if (pathname === '/') {
      return true;
    }

    const regex = new RegExp(pagesHaveSubscribe.join('|'), 'i');
    return regex.test(pathname);
  };

  render() {
    const { url } = this.props.match;
    const { history, location } = this.props;

    const appHeight = window.innerHeight;
    const pathName = get(location, 'pathname');

    const isWithoutHoc = pageWithoutHoc.indexOf(pathName) !== -1;

    const stying = {
      flexDirection: 'row',
      backgroundColor: 'white',
      overflow: 'hidden'
    };

    const isComingAndHome = isComing && pathName === '/';

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          {/* <div style={stying}>
            {
              isWithoutHoc
                ? (
                  <AppRouter history={history} style={{ height: '100%' }} url={url} />
                ) : (
                  <div>
                    { pathName !== '/' && <Navbar history={history} url={url} /> }
                    <div
                      id="root-container"
                      className="isoContentMainLayout main-container"
                      style={{ width: '100vw', height: 'auto', backgroundColor: 'white', overflowX: 'hidden' }}
                    >
                      <AppRouter history={history} url={url} />
                      <FooterLayout whiteColorSubcribe={includes(whiteColorSubscribe, pathName)} />
                    </div>
                  </div>
                )
            }
          </div> */}
          {(this.props.isLoading && pathName === '/') && <Loading pageLoading={true} /> }

          <Layout style={{ height: appHeight }}>
            {
              isWithoutHoc
                ? (
                  <AppRouter style={{ height: '100%' }} url={url} />
                ) : (
                  <Layout>
                    {/* comment for dark transaction phone nav on header */}
                    { pathName !== '/' && <Navbar history={history} url={url} /> }
                    {/* <Navbar history={history} url={url} />  */}
                    <Layout style={stying}>
                      <Layout
                        className="isoContentMainLayout main-container"
                        style={{
                          height: isComingAndHome ? '100vh' : 'auto',
                          backgroundColor: isComingAndHome ? '#000' : 'white',
                          overflowX: 'hidden',
                          overflowY: isComingAndHome ? 'hidden' : 'scroll',
                          width: '100vw'
                        }}
                      >
                        <AppRouter history={history} url={url} />

                        <FooterLayout whiteColorSubcribe={includes(whiteColorSubscribe, pathName)} />
                      </Layout>
                    </Layout>
                  </Layout>
                )
            }
          </Layout>

        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    height: state.common.height,
    isOverBanner: state.common.isOverBanner,
    isLoading: state.common.isLoading
  }),
  dispatch => bindActionCreators({
    toggleAll,
    initServiceWorker,
    setShowSubscribeContainer,
    getOverScaleBanner,
    getTransparentNav,
    isPageLoading
  }, dispatch)
)(App);
