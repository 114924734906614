import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const Wrapper = styled.div`
  margin: auto;
  border-radius: 6px;
  background-color: white;
  background: #000A14;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index:1;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0px 32px 0 rgb(0 0 0 / 10%);

  .left-comming {
    width: 53%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding:50px 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ant-form-item-explain {
      color: #fff;
    }

    .MuiInputLabel-formControl {
      top: 10%;
    }

    .MuiInputLabel-shrink {
      transform: translate(0, -4px) scale(0.75);
      transform-origin: top left;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form label {
      color: #afc5ff;
      margin-left: 10px;
    }

    .ant-form label:focused {
      margin-left: 0;
    }

    .email-field {
      display: flex;

      .email-style {
        width: 100%;
      }

      input {
        width: 100%;
        height: 30px;
        border-radius: 5px;
        border: 1px solid white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding-left: 10px;
        padding-right: 10px;
        color: white;
      }

      .MuiFormLabel-root.Mui-focused {
        color: #afc5ff;
      }

      .MuiInput-underline:before {
        border: 0;
      }

      .MuiInput-underline:after {
        border: 0;
      }
    }

    .privacy-intro {
      color: #fff;
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      a {
        color: white;
        text-decoration: underline;
      }

      a:hover {
        color:var(--primary);
      }
    }

    .subcribe-btn {
      height: 45px;
      width: 100%;
      border: none;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: white;
      margin-top: 16px;
      color: #3b6df1;
      font-weight: 600;
    }

    .comming-title {
      color: white;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 10px;
      line-height: 120%;
  }

    .comming-intro {
      color: white;
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 600;
   }
  }

  .ellipse-bottom {
    top: 0;
    right: 0;
    width:43%;
    height: 100%;
    position: absolute;
  }

  .right-comming {
    width: 40%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .left-phone-overlay {
    top: 0px;
    right: 20%;
    width: 24%;
    height: auto;
    position: absolute;
}

  .right-phone-overlay {
    top: 0;
    right: 0;
    width: 20%;
    height: auto;
    position: absolute;
  }

  @media only screen and (max-width: 767px) {
    .left-comming {
      width: 100%;
      padding: 20px;
  
      .comming-title {
        font-size: 20px;
      }
  
      .comming-intro {
        font-size: 14px;
      }

      .subcribe-btn {
        width: 100%;
      }
    }

    .ellipse-bottom {
      display: none;
    }
  }


  .subscribe_field {
    position:relative;
    label + .MuiInput-formControl {
      margin-top: 0;
    }
    .MuiInput-input {
      width: 100%;
      display: block;
      border: 2px solid #fff;
      color: #fff;
      background: transparent;
      border-radius: 6px;
      padding: 0 170px 0 24px;
      height: 50px;
   }
   .MuiInput-underline:before,
    .MuiInput-underline:after{
     display:none;
   }
  }
  .left-comming .subscribe_field .MuiInputLabel-formControl {
      font-size: 17px;
      color: #fff;
      left: 0;
      top: 12px;
      margin-left: 24px;
      transform: none;
      line-height: 30px;
      transition:all 0.3s;
  }
  .left-comming .subscribe_field .MuiInputLabel-formControl.MuiInputLabel-shrink {
    font-size: 10px;
    top: -4px;
  }
   .subscribe_field .ant-btn.subcribe-btn {
    width: 155px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 54px;
    padding: 0 30px;
    border-radius: 0 5px 5px 0 !important;
    color: var(--dark);
    font-size: 17px;
    font-weight: 700;
    background: #fff;
    transition: all 0.4s;
  }
   .subscribe_field .ant-btn.subcribe-btn:hover {
    color: #fff;
    background: var(--primary);
    border: 2px solid #fff;
    border-left-width: 0;
  }




  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .left-comming{
      padding:30px;
    }
    .left-comming .comming-title {
      font-size: 22px;
      margin-bottom: 8px;
   }
   .left-comming .comming-intro {
      font-size: 17px;
      margin-bottom: 15px;
      line-height: 140%;
  } 
  .subscribe_field .MuiInput-input {
    padding: 0 130px 0 15px;
    height: 42px;
    font-size: 14px;
  }
  .subscribe_field .ant-btn.subcribe-btn {
    width: 125px;
    height: 46px;
    padding: 0 10px;
    font-size: 15px;
  }
  .left-comming .subscribe_field .MuiInputLabel-formControl.MuiInputLabel-shrink {
    top: -6px;
  }
  .left-comming .subscribe_field .MuiInputLabel-formControl {
    font-size: 14px;
    top: 7px;
    margin-left: 18px;
  }
  .left-comming .privacy-intro {
    margin-top: 8px;
    font-size: 12px;
    line-height: 140%;
  }
  .right-phone-overlay {
    width: 23%;
  } 
  .left-phone-overlay {
    width: 27%;
  }
    
  }



  @media  (max-width: 767px) {
    .left-comming {
      padding: 15px;
      text-align: center;
      display:block;
    }
    .left-comming .comming-title {
      font-size: 17px;
      margin-bottom: 6px;
  }
  .left-comming .comming-intro {
    font-size: 13px;
    margin-bottom: 15px;
    line-height: 140%;
    font-weight: normal;
}
.subscribe_field .MuiInput-input {
  padding: 0 100px 0 12px;
  height: 38px;
  font-size: 12px;
  border-width: 1px;
  border-radius: 4px;
}
.subscribe_field .ant-btn.subcribe-btn {
  width: 95px;
  height: 40px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 40px;
}
  .left-comming .subscribe_field .MuiInputLabel-formControl.MuiInputLabel-shrink {
    top:-7px;
    font-size:9px;
  }
  .left-comming .subscribe_field .MuiInputLabel-formControl {
    font-size: 12px;
    top: 5px;
    margin-left: 13px;
  }
  .left-comming .privacy-intro {
    margin-top: 8px;
    font-size: 10px;
    line-height: 140%;
  }
  .right-phone-overlay,
  .left-phone-overlay {
    display:none;
  }
  .ellipse-bottom {
    width: 54%;
    display: block;
    right: 0;
    z-index:-1;
}
    
  }



`;

export default WithDirection(Wrapper);
