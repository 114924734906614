import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';

import { mailContact } from '../../actions/email';
import ModalEmailContact from '../ModalEmailContact';

import SubscribeForm from './subscibeForm';
import SubscribeWrapper from './styles';

class Subscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  onShowModal = () => {
    this.setState({ isVisible: true });
  };

  onCloseModal = () => {
    this.setState({ isVisible: false });
  };

  onSubmit = (values, resetForm) => {
    const { mailContact } = this.props;

    const reqBody = {
      email: values.email,
      type: 'SUBSCRIBER',
    };

    this.onShowModal();

    mailContact(reqBody);
    if (resetForm) {
      resetForm();
    }
  };

  render() {
    const { isVisible } = this.state;
    const { bottom, mailContactRes } = this.props;

    const isRequesting = get(mailContactRes, 'requesting');

    return (
      <SubscribeWrapper bottom={bottom}>
        <div className="left-comming">
          <h1 className="comming-title">Stay Informed About ClickStream</h1>
          <p className="comming-intro">Get updates on CLIS news and products.</p>
          <SubscribeForm
            isRequesting={isRequesting}
            onSubmit={this.onSubmit}
          />
        </div>

        <img src={`${process.env.PUBLIC_URL}/images/ellipse.png`} className="ellipse-bottom" alt="bottom" />
        <img className="left-phone-overlay" src={`${process.env.PUBLIC_URL}/images/clic-app.png`} alt="left" />
        <img className="right-phone-overlay" src={`${process.env.PUBLIC_URL}/images/subscribe-phone.png`} alt="right" />

        <ModalEmailContact
          isVisible={isVisible}
          onCloseModal={this.onCloseModal}
          title="Thank You for subscribing"
          messages="You are awesome"
        />
      </SubscribeWrapper>
    );
  }
}

export default connect(
  state => ({
    mailContactRes: state.email.mailContact,
  }),
  dispatch => bindActionCreators({
    mailContact,
  }, dispatch),
)(Subscribe);
