import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const FooterWrapper = styled.div`
  background-color: ${props => props.whiteColorSubcribe ? '#fff' : '#fff'};

  .subscribe-box-parent {
      width: 100%;
      padding: 80px 0;
      background: #fff;
    .subscribe-box-children {
      max-width: 1200px;
      margin: auto;
      position: relative;
    }
  }
  .subscribe-box-parent:empty {
    display: none;
  }

  .footer-bottom {
    padding: ${props => props.showSubscribe ? '80px 0' : '80px 0'};
    p {
      color: #09090f;
    }
    h6 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom:20px;
    }

    .col-1st {
      .copy-right {
        padding: 0;
        font-size: 16px;
        line-height: 170%;
      }
      .logo {
        width: auto;
        height: 30px;
        object-fit: contain;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        margin-top: 15px;
      }
    }

    .col-2nd {

      nav {
        a{
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: black;
          margin: 5px 0;
          font-size: 15px;
         }

        a:hover {
          color: #2062b4;
        }
      }
    }

    .col-3rd {

      nav {
        a{
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: black;
          margin: 5px 0;
          font-size: 15px;
         }

        a:hover {
          color: #2062b4;
        }
      }
    }

    .col-4th {
      nav {
        .social-icon {
          height: 24px;
          margin-right: 10px;
          display:inline-block;
          transition:all 0.4s;
        }
        .social-icon:hover {
          transform:scale(1.1);
        }
      }
    }
  }

  .subscribe-box-parent {
    // position: absolute;
    // bottom: 72%;
    // left: 0;

    .subscribe-container {
      // width: 100%;
      // padding: 0 100px;
  
      .subscribe-wrapper {
        height: 280px;
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .divide {
      padding: 50px 10px;
    }

    .footer-bottom {
      padding-top: ${props => props.showSubscribe ? '50px' : '50px'};
      padding-bottom: 50px;
      padding-left: 25px;
      padding-right: 25px;

      .col-1st {
        p {
          font-size: 15px;
          margin-top: 0;
        }
      }

      .col-2nd {
  
        nav {
          a {
            font-size: 14px;
          }
        }
      }

      .col-3rd {
  
        nav {
          a {
            font-size: 14px;
          }
        }
      }

      .col-4th {
  
        nav {
          .social-icon {
            widht: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
      }

    }
  }

  @media only screen and (max-width: 767px) and (min-width: 576px) {
    .subscribe-box-parent {

      .subscribe-container {
        padding: 0 25px;
  
        .subscribe-wrapper {
          height: 240px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .subscribe-box-parent {
      .subscribe-container {
        padding: 0 10px;
  
        .subscribe-wrapper {
          // height: 220px;
        }
      }
    }

    .logo {
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .divide {
      padding: 50px 60px;
    }

    .footer-bottom {
      .col-1st {
        p {
          font-size: 18px;
          margin-top: 0;
        }
      }

      .col-2nd {
        nav {
          a {
            font-size: 16px;
          }
        }
      }

      .col-3rd {
  
        nav {
          a {
            font-size: 16px;
          }
        }
      }

      .col-4th {
        margin-bottom: 50px;
  
        nav {
          .social-icon {
            widht: 25px;
            height: 25px;
            margin-right: 10px;
          }
        }
      }

    }

    .logo {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .subscribe-container {
      padding: 0 50px;
    }
  }

  @media (min-width: 1200px) {
    .subscribe-container {
      padding: 0;
    }
  }

  
@media (min-width:992px) and (max-width:1199px){ 
  .footer-bottom .col-1st .copy-right {
    font-size: 15px;
  }
  .left-comming {
    padding: 40px 50px;
  }
}

@media (min-width:768px) and (max-width:991px){
  .subscribe-box-parent {
      padding: 70px 0;
  }
  .footer-bottom .col-2nd nav a ,
  .footer-bottom .col-3rd nav a {
    font-size: 14px;
  }
  .footer-bottom .col-1st .copy-right {
    font-size: 13px;
  }
  .footer-bottom {
    padding: 50px 0;
  }

}

@media (max-width:767px){
  .subscribe-box-parent {
      padding: 40px 0;
  }
  .footer-bottom .col-2nd nav a ,
  .footer-bottom .col-3rd nav a {
    font-size: 14px;
  }
  .footer-bottom .col-1st .copy-right {
    font-size: 13px;
  }
  .footer-bottom {
    padding: 40px 0;
  }

  .footer-bottom h6 {
    margin-bottom: 10px;
  }
}

`

export default WithDirection(FooterWrapper);