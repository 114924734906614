import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../Pages/HomePage'))
  },
  {
    path: 'contact',
    component: asyncComponent(() => import('../Pages/Contact'))
  },
  {
    path: 'customer-support',
    component: asyncComponent(() => import('../Pages/CustomerSupport'))
  },
  {
    path: 'ambassador',
    component: asyncComponent(() => import('../Pages/Ambassador'))
  },
  // {
  //   path: 'quarterly-fillings',
  //   component: asyncComponent(() => import('../Pages/Quarterly'))
  // },
  // {
  //   path: 'annual-report',
  //   component: asyncComponent(() => import('../Pages/Report'))
  // },
  {
    path: 'press-release',
    component: asyncComponent(() => import('../Pages/PressRelease'))
  },
  {
    path: 'about',
    component: asyncComponent(() => import('../Pages/About'))
  },
  {
    path: 'overview',
    component: asyncComponent(() => import('../Pages/Overview'))
  },
  {
    path: 'legal-notice',
    component: asyncComponent(() => import('../Pages/Legacy'))
  },
  {
    path: 'privacy-policy',
    component: asyncComponent(() => import('../Pages/Policy'))
  },
  {
    path: 'disclaimers',
    component: asyncComponent(() => import('../Pages/Disclaimers'))
  },
  {
    path: 'our-products',
    component: asyncComponent(() => import('../Pages/OurProduct'))
  },
  {
    path: 'all-press-release',
    component: asyncComponent(() => import('../Pages/PressAll'))
  },
  {
    path: 'all-press-release/:slug',
    component: asyncComponent(() => import('../Pages/PressDetail'))
  },
  {
    path: 'unsubscribe',
    component: asyncComponent(() => import('../Pages/Unsubscribe'))
  },
  {
    path: 'sec-filings',
    component: asyncComponent(() => import('../Pages/SecFilling'))
  }
];

class AppRouter extends Component {
  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        {routes.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact !== false}
              key={singleRoute.path}
              path={`/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}
export default AppRouter;
