import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';

import MainRoutes from './navigator';
import configureStore from './configureStore';

import themes from './settings/themes';
import { themeConfig } from './settings';
import DashAppHolder from './dashAppStyle';

const initialState = {};
const { store, history, persistor } = configureStore(initialState);

const DashApp = (props) => {

  useEffect(() => {

  }, []);

  return (
      <ConfigProvider locale={enUS}>
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <DashAppHolder>
          <PersistGate persistor={persistor}>
            <Provider store={store}>
              <MainRoutes history={history} />
            </Provider>
          </PersistGate>
        </DashAppHolder>
      </ThemeProvider>
    </ConfigProvider>
  );
}


export default DashApp;
