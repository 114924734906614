import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const NavWrapper = styled.div`
  background-color: ${props => props.isHomepage ? 'transparent' : '#fff'};
  position: fixed;
  padding: 14px 0;
  z-index: 999;
  border-bottom:${props => props.isHomepage ? '0px' : '0px'};
  width: 100%;
  transition:all 0.3s;
  margin-bottom: ${props => props.isInvestorBrowser ? '60px' : '0'};
  @media (max-width:767px){
    padding: 15px 0;
  }

  .logo-container {
    .logo {
      width: auto;
      height: 40px;
      object-fit: contain;
    }
  }

  .menu-container {
    display: flex;
    justify-content: center;
    font-family: 'Proxima Nova';
    .parent-nav {
      a {
        font-size: 14px;
        font-weight: 600;
        color: #b8ceff;
        margin: 0 20px;
        padding: 5px 0;
      }

      // #investor-label:hover {
      //   border-bottom: 2px solid #BEBEC3;
      // }

      // #investor-label:actived {
      //   border-bottom: 0;
      // }

      // a:hover {
      //   border-bottom: 2px solid #BEBEC3;
      // }

      // a:visited {
      //   border-bottom: 2px solid #BEBEC3;
      // }

      // a:actived {
      //   border-bottom: 2px solid #1A4CA4;
      // }
    }
  }

  .get-btn-container {
    display: flex;
    justify-content: flex-end;

    .notified-btn {
		height: auto;
		background: ${props => props.isHomepage ? '#fff' : 'var(--dark)'};
		border-color: transparent;
		padding: 0 25px;
		border-radius: 6px;
		font-weight: 600;
		color: ${props => props.isHomepage ? 'var(--dark)' : '#fff'};
		letter-spacing: 1px;
		transition:all 0.5s; 
		font-size: 16px;
		line-height: 40px;
	}
    .notified-btn:hover {
		background: ${props => props.isHomepage ? 'var(--primary)' : 'var(--primary)'};
		color:#fff;
	}
  }

  .flipInX {
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
  }

  @-webkit-keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  @keyframes flipInX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  .flipOutX {
    -webkit-animation-name: flipInX;
    animation-name: flipOutX;
  }
  @keyframes flipOutX {
    0% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    100% {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    } 
  } 

  .icon-container {
    .icon {
      display: none;
    }
    .icon-collapse {
      color: ${props => props.isHomepage ? '#fff' : '#000'}
    }
    .in {
      animation: flipInX .2s ease-in;
    }
    .out {
      animation: flipOutX .2s ease-in;
    }
  }

  .dropdown-container {
    background-color: #fff;
    width: 100%;
    position: absolute;
    z-index: -1;
    overflow: auto;
    max-height: calc(100vh - 68px);
    left:0;
    border-top:0.5px solid #f2f2f3;
    top:100%;
    margin:0;
    transform: ${props => props.isToggle ? 'translateY(0)' : 'translateY(-120%)'};
    opacity: ${props => props.isToggle ? 1 : 0};
    transition: transform .5s, opacity .4s;
    .item-dropdown {
      padding: 20px;
      border-bottom: 0.5px solid #f2f2f3;

      #investor-label { 
        font-size: 15px;
        font-weight: 600;
        color: black;
      }

      .dropdown-investors-mobile {
        .children-investor {
          padding: 10px;
          border-left: 0.5px solid #f2f2f3;

          .label-children {
            font-size: 13px;
            color: black;
          }
        }
      }
    }
  }

  .dropdown-investors-browser {
    width: 100%;
    height: 60px;
    background-color:#F3F3F8;
    left:0;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: -1;
    top:100%;
    opacity: ${props => props.isInvestorBrowser ? 1 : 0};
    pointer-events: ${props => props.isInvestorBrowser ? 'inherit' : 'none'};
    transition: transform 2s, opacity 1s;
    .investor-item { 
      color: black;
      margin: 10px 20px;
      font-size: 16px;
      color:#44444f;
      font-weight: 400;
    }

    .investor-item:hover {
      color: #000000;
    }

    .investor-item.actived {
      color: #000000;
      font-weight:600;
    }
  }

  @media only screen and (max-width: 767px) {

    .menu-container {
      display: none;
    }

    .icon-container {
      a {
        color: gray;
        font-size: x-large;
      }

      .icon {
        text-align: right;
        display: block;
        font-size: 28px;

        i:active {
          .dropdown {
            display: block;
          }
        }
      }
    }

    .get-btn-container {
      display: flex;
      justify-content: flex-end;

    }

    .dropdown-investors-browser {
      display: none
    }
  }

  .bar_toggle {
    display: block;
    width: 34px;
    height: 34px;
    position: relative;
    margin-left:auto;
}
.bar_toggle i {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.isHomepage ? '#fff' : 'var(--dark)'};
  height: 3px;
  border-radius: 8px;
  margin: auto;
  width:30px;
  transition:all 0.3s;
  transform-origin:left center;
}
.bar_toggle i:nth-child(1) {
  top: -18px;
}
.bar_toggle i:nth-child(3) {
  top: 18px;
}
.bar_toggle.bar_toggle_show i:nth-child(2) {
  left:8px;
  opacity:0;
}
.bar_toggle.bar_toggle_show i:nth-child(1) {
  transform:rotate(45deg);
  top:-22px;
}
.bar_toggle.bar_toggle_show i:nth-child(3) {
  transform:rotate(-45deg);
  top:22px;
}

.dropdown-container .item-dropdown .dropdown-investors-mobile {
  margin-left: 0;
}
  @media (min-width: 768px) and (max-width: 991px) {

    .menu-container {
      display: none;
    }

    .icon-container {
      a {
        color: gray;
        font-size: xx-large;
      }

      .icon {
        text-align: center;
        display: block;
      }
    }

    .get-btn-container {
      display: flex;
      justify-content: flex-start;

    }

    .dropdown-container {
      .item-dropdown {
        padding:13px 25px;
  
        a { 
          font-size: 20px;
        }
      }
    }

    .dropdown-investors-browser {
      display: none
    }
  }

  @media (min-width:768px) and (max-width:991px){
    .get-btn-container .notified-btn {
      padding: 0 20px;
      font-size: 14px;
      line-height: 38px;
   }
   .navbar-container.container{
     padding-left:30px;
     padding-right:30px;
   }
  }

  @media (max-width:767px){
    .get-btn-container .notified-btn {
      padding: 0 8px;
      font-size: 12px;
      line-height: 30px;
      border-radius: 4px !important;
      letter-spacing: 0.5px;
  }
   .bar_toggle {
      width: 26px;
      height: 26px;
  }
  .bar_toggle i {
    height: 2px;
    width: 25px;
  }
  .bar_toggle i:nth-child(1) {
    top: -16px;
  }
  .bar_toggle i:nth-child(3) {
    top: 16px;
  }
  .logo-container .logo {
    height: 22px;
  }
  .navbar-container .get-btn-container{
    padding-right:0;
  }
  .dropdown-container .item-dropdown {
    padding: 12px  20px;
  }
  .dropdown-container {
    max-height: calc(100vh - 60px);
  }
  .bar_toggle.bar_toggle_show i:nth-child(1) {
    top: -17px;
  }
  .bar_toggle.bar_toggle_show i:nth-child(3) {
    top: 17px;
  }
  .dropdown-container .item-dropdown #investor-label {
    font-size: 13px;
  }
  .dropdown-container .item-dropdown .dropdown-investors-mobile {
    margin-left: 0;
  }
  .dropdown-container .item-dropdown .dropdown-investors-mobile .children-investor {
      padding: 5px 10px;
  }
  .dropdown-container .item-dropdown .dropdown-investors-mobile .children-investor .label-children {
      font-size: 11px;
  }


  }



`

export default WithDirection(NavWrapper);
