/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';

import { Button, Form } from 'antd';

import FieldFloatingValidation from '../../components/Validation/FieldFloatingValidation';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required.'),
});

class SubscribeForm extends Component {
  static propTypes = {
    onChangeCatalog: PropTypes.func,
    rewardsCatalogData: PropTypes.array,
  };

  render() {
    const { handleSubmit, isRequesting } = this.props;

    return (
      <Form {...formItemLayout} layout="horizontal" style={{ width: '100%' }}>
        <div className="subscribe_field">
          <Field
            name="email"
            type="text"
            label=""
            component={FieldFloatingValidation}
            containerClassName="email-style"
            required
          />
          <Button
            onClick={handleSubmit}
            className="subcribe-btn"
            disabled={isRequesting}
            loading={isRequesting}
          >
            Subscribe
          </Button>
        </div>
        <p className="privacy-intro">We care about protecting your data. Here’s our <a href="/privacy-policy">Privacy Policy</a>.</p>
      </Form>
    );
  }
}

export default compose(
  connect(
    state => ({}),
    dispatch => bindActionCreators({}, dispatch),
  ),
  withFormik({
    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {};
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      props.onSubmit(values, resetForm);
      setSubmitting(false);
    },
  }),
)(SubscribeForm);
