import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { get } from 'lodash';

const { TextArea } = Input;
/* eslint-disable */
class FieldValidation extends Component {
  onChange = (e) => {
    const { form } = this.props;
    const { handleChange } = form;

    handleChange(e)
  };

  render() {
    const {
      type,
      id,
      label,
      field,
      placeholder,
      form,
      required,
      inputSize,
      containerClassName,
      textArea,
      formItemLayout,
      isDisableInput,
      onBlur,
      onFocus,
      count,
      customStyle,
      footerComponent,
      inputProps,
      rows,
      addonAfter,
      fontFamily,
      fontSize,
      fontWeight
    } = this.props;
    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    if (textArea) {
      return (
        <Form.Item
          {...formItemLayout}
          id={id}
          label={label}
          required={required}
          className={containerClassName}
          validateStatus={validateStatus}
          help={fieldError}
        >
          <TextArea
            name={field.name}
            value={field.value}
            type={type || 'text'}
            onChange={this.onChange}
            size={inputSize || 'large'}
            placeholder={placeholder || ''}
            rows={rows}
            {...inputProps}
            style={{ borderRadius: 5, fontFamily, fontSize, fontWeight }}
          />
          {footerComponent}
        </Form.Item>
      )
    }

    return (
      <Form.Item
        {...formItemLayout}
        id={id}
        label={label}
        required={required}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
        style={customStyle}
      >
        <Input
          onBlur={onBlur}
          name={field.name}
          value={field.value}
          type={type || 'text'}
          onChange={this.onChange}
          size={inputSize || 'large'}
          placeholder={placeholder || ''}
          disabled={isDisableInput}
          count={count}
          addonAfter={addonAfter}
          onFocus={onFocus}
          {...inputProps}
          style={{ borderRadius: 5, height: 45, fontFamily, fontWeight }}
        />
        {footerComponent}
      </Form.Item>
    );
  }
}

export default FieldValidation;
