import React from 'react';

import { get } from 'lodash';

import LoadingWrapper from './styles';
import Lottie from 'react-lottie';
import loader from '../../lotties/loader.json';

const Loading = (props) => {
  const pageLoading = get(props, 'pageLoading', false);
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <LoadingWrapper pageLoading={pageLoading}>
      <div className="loader-element" >
        <Lottie options={defaultOptions} />
      </div>

    </LoadingWrapper>
  );
}

export default Loading;
