import Category from './category';
import Email from './email';
import Member from './member';
import StockChart from './stockChart';

export default {
  Category,
  Email,
  Member,
  StockChart
};
