import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Modal } from 'antd';

import { mailContact } from '../../actions/email';
import { widthModal } from '../../utils/common';

import PropCustomForm from './form';
import BodyWraper from './styles';
import isUndefined from 'lodash/isUndefined';

const _ = { isUndefined };

class ModalEmailContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successLogo: false,
      title: '',
      messages: '',
      isHasForm: false,
    };
  }

  onSubmit = (values, resetForm) => {
    const { mailContact } = this.props;

    const reqBody = {
      email: values.email,
      type: 'SUBSCRIBER',
    };

    // console.log("showThanksModal : ", this.props.showThanksModal);

    // if(this.props.showThanksModal) {

    //   this.setState({
    //     isHasForm: false,
    //     title: "Thank You for subscribing",
    //     messages: "You are awesome"
    //   });

    // } else {
    //   onCloseModal();
    // }

    mailContact(reqBody);
    if (resetForm) {
      resetForm();
    }
  };

  componentDidMount() {

    const {
      title,
      messages,
      isHasForm,
    } = this.props;


    console.log(title, messages);

    this.setState({
      title: title,
      messages: messages,
      isHasForm: isHasForm,
    });


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.requesting !== this.props.requesting && this.props.requesting === false && !_.isUndefined(this.props.showThanksModal)) {
      if (this.props.showThanksModal) {
        this.setState({
          isHasForm: false,
          title: 'Thank You for subscribing',
          messages: 'You are awesome',
        });

      } else {
        this.props.onCloseModal();
      }
    }
  }

  render() {
    const {
      isVisible,
      onCloseModal,
    } = this.props;


    const {
      successLogo,
      title,
      messages,
      isHasForm,
    } = this.state;

    return (
      <Modal
        visible={isVisible}
        footer={null}
        closable={false}
        bodyStyle={{
          padding: 10,
        }}
        width={widthModal(window.innerWidth)}
        centered={true}
      >
        <BodyWraper>
          <Button
            className="close-btn"
            onClick={onCloseModal}
          >
            <img src={`${process.env.PUBLIC_URL}/close.png`} alt="" />
          </Button>

          {successLogo
            ? <img className="logo" src={`${process.env.PUBLIC_URL}/images/success-logo.png`} alt="" />
            : <img className="logo" src={`${process.env.PUBLIC_URL}/black-logo.png`} alt="" />
          }
          <h1 className="title">{title || ''}</h1>
          <p className="messages">{messages || ''}</p>

          {
            isHasForm ? (
              <PropCustomForm
                onSubmit={this.onSubmit}
              />
            ) : (
              <Button
                className="back-btn"
                onClick={onCloseModal}
              >
                Back to Home
              </Button>
            )
          }
        </BodyWraper>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    collapsed: state.common.collapsed,
    requesting: state.email.mailContact.requesting,
  }),
  dispatch => bindActionCreators({
    mailContact,
  }, dispatch),
)(ModalEmailContact);
