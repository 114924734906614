/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'antd';

import Subscribe from '../../components/Subscribe';

import FooterWrapper from './styles';

class Footer extends Component {
  render() {
    const { showSubscribe, whiteColorSubcribe } = this.props;
    return (
      	<FooterWrapper showSubscribe={showSubscribe} whiteColorSubcribe={whiteColorSubcribe}>
			<div className="container">
				<div className="subscribe-box-parent">
					{showSubscribe &&
						<Subscribe />
					}
				</div>
			</div>

			<footer style={{ backgroundColor: '#F5F8FF' }}>
				<div className="container">
					<Row align="top" className="footer-bottom justify-content-between" gutter={30}>
						<Col xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 9, order: 0 }} lg={{ span: 8, order: 0 }} className="col-1st">
							<a href="/"><img className="logo" src={`${process.env.PUBLIC_URL}/images/logo/logo.png`} alt="" /></a>
							<p className="copy-right">© Copyright 2021 ClickStream Corporation<br /> All Rights Reserved.</p>
						</Col>

						<Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 4, order: 1 }} lg={{ span: 5, order: 1 }} className="xs-mb-4 col-2nd">
							<h6>Company</h6>
							<nav>
								<a href="/">Home</a>
								<a href="about">About</a>
								<a href="overview">Investors</a>
								{/* <a href="press-release">Press</a> */}
								<a href="contact">Contact</a>
							</nav>
						</Col>

						<Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 2 }} md={{ span: 4, order: 2 }} lg={{ span: 5, order: 2 }} className="xs-mb-4 col-3rd">
							<h6>Legal</h6>
							<nav>
								<a href="legal-notice">Legal Notice</a>
								<a href="privacy-policy">Privacy Policy</a>
								<a href="disclaimers">Disclaimers</a>
							</nav>
						</Col>

						<Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 2 }} md={{ span: 7, order: 3}} lg={{ span: 4, order: 3 }} className="xs-mb-4 col-4th">
							<h6>Follow us</h6>
							<nav>
								<a target="_blank" href="https://www.youtube.com/watch?v=v1wJ4r4Tf-I&t=2s">
								<img src={`${process.env.PUBLIC_URL}/social-youtube.png`} className="social-icon" alt="" />
								</a>
								<a target="_blank" href="https://twitter.com/clickstreamc?lang=en">
								<img src={`${process.env.PUBLIC_URL}/social-twitter.png`} className="social-icon" alt="" />
								</a>
								<a target="_blank" href="https://www.linkedin.com/company/clickstream-corporation/about/">
								<img src={`${process.env.PUBLIC_URL}/social-linkedin.png`} className="social-icon" alt="" />
								</a>
								{/* <a target="_blank" href="https://www.facebook.com/clictechnology">
								<img src={`${process.env.PUBLIC_URL}/social-facebook.png`} className="social-icon" alt="" />
								</a> */}
								<a target="_blank" href="https://www.instagram.com/heypalapp/?hl=en">
								<img src={`${process.env.PUBLIC_URL}/social-instagram.png`} className="social-icon" alt="" />
								</a>
							</nav>
						</Col>
					</Row>
				</div>
			</footer>
      	</FooterWrapper>
    )
  }
}

export default connect(
  state => ({
    showSubscribe: state.common.showSubscribe
  }),
  dispatch => bindActionCreators({

  }, dispatch)
)(Footer);
