import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import category from './category';
import common from './common';
import email from './email';
import member from './member';
import stockChart from './stockChart';

const commonConfig = {
  key: 'common',
  storage: localForage,
  whitelist: []
};


export default {
  category,
  email,
  common: persistReducer(commonConfig, common),
  member,
  stockChart
};
