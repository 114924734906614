/* eslint-disable complexity */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Field, withFormik } from 'formik';
import { Button, Form } from 'antd';
import * as Yup from 'yup';

import FieldValidation from '../Validation/FieldValidation';

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 24 },
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required.'),
});

class PropCustomForm extends Component {
  static propTypes = {
    isRequesting: PropTypes.bool,
    handleSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      label: '',
    };
  }

  onBlur = () => {
    this.setState({ label: '' });
  };

  onFocus = () => {
    this.setState({ label: 'Your email' });
  };

  render() {
    // const { label } = this.state;
    const { handleSubmit } = this.props;

    const button = (
      <Button
        onClick={handleSubmit}
        className="subcribe-btn"
      >
        Subscribe
      </Button>
    );

    return (
      <Form {...formItemLayout} layout="horizontal" style={{ width: '100%' }}>
        {/*  <p style={{ color: 'gray', marginBottom: 10, fontWeight: 300 }}>{label}</p> */}
        <div className="subscribe_form_field">
          <Field
            name="email"
            label=""
            type="text"
            placeholder="Your email"
            component={FieldValidation}
            addonAfter={button}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            required
          />
        </div>
      </Form>
    );
  }
}

export default compose(
  connect(
    state => ({}),
    dispatch => bindActionCreators({}, dispatch),
  ),
  withFormik({
    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return {};
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      props.onSubmit(values, resetForm);
      setSubmitting(false);
    },
  }),
)(PropCustomForm);
