import { handleActions } from 'redux-actions';

const initialState = {
  infoStock: {
    result: null,
    error: null,
    requesting: false
  }
};

export const stockChart = handleActions({
  /** FETCH INFO STOCK **/
  FETCH_INFO_STOCK_REQUEST: (state) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: true
    }
  }),
  FETCH_INFO_STOCK_SUCCESS: (state, { payload }) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_INFO_STOCK_FAIL: (state, { payload }) => ({
    ...state,
    infoStock: {
      ...state.infoStock,
      requesting: false,
      error: payload.error
    }
  })
}, initialState);

export default stockChart;
