import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const BodyWraper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;

  .close-btn {
    align-self: flex-end;
    border: 0;
    padding: 0 10px;
    background:transparent !important;

    img {
      width: 24px;
      height: 24px;
    }
  }
.ant-form {
  margin-top:40px;
  margin-bottom:20px;
}
  .logo {
    width: 300px;
    height: auto;
    margin-bottom: 35px;
}

  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }

  .messages {
    color: #535564;
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 180%;
    text-align: center;
    margin: 0 110px;
}

  .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-form-item-control-input {
    align-items: flex-start;
    min-height: 50px;
  }

  .ant-input-group .ant-input {
    color: black;
    font-weight: 500;
    border-radius: 6px;
    height: 50px;
    display: block;
    width: 100%;
    border: 2px solid #ddd;
    padding: 0 160px 0 18px;
    font-size: 18px;
    box-shadow: 0 0 0 transparent;
    float:none;
  }
  .ant-input-group-addon{
    position:static;
  }
  .subscribe_form_field .ant-btn.subcribe-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    border-radius: 0 6px 6px 0 !important;
    font-size: 17px;
    z-index:6;
  }

  .form-container {
    padding: 0 50px;

    .email-field {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  
    .subcribe-btn {
      height: 40px;
      width: 100%;
      border: none;
      border-radius: 5px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: #2047E9;
      color: #fff;
      font-weight: 600;
    }
  }

  .back-btn {
    border: 1px solid white;
    border-radius: 5px;
    background-color: #155bf6;
    margin: 10px 0;
    height: 60px;
    padding: 0 50px;
    font-weight: 600;
    color: #fff;
    font-size: 15px;
  }
  .subscribe_form_field {
    max-width: 470px;
    margin: 0 auto;
}

  @media only screen and (max-width: 767px) {
    .title {
      font-size: 18px;
    }
    .logo {
        width: 180px;
        margin-bottom: 20px;
    }
    .messages {
      font-size: 14px;
      line-height: 150%;
      margin: 0 0;
    }
    .ant-form {
      margin-top: 20px;
      margin-bottom: 0;
    }
    .ant-input-group .ant-input {
      height: 42px;
      font-size: 15px;
      padding: 0 105px 0 15px;
    }
    .ant-form-item-control-input {
      min-height: 42px;
   }
   .close-btn img {
      width: 19px;
      height: 19px;
   }
   .close-btn{
     line-height:40px;
   }
   .ant-modal-content {
    border-radius: 16px;
    }
    .subscribe_form_field .ant-btn.subcribe-btn {
      height: 42px;
      font-size: 14px;
      padding: 0 15px;
    }
    .subscribe_form_field {
      max-width: 380px;
    } 

    .form-container {
      padding: 0 10px;
      text-align: left;

      .email-field {
        margin-right: 20px;
      }
    
      .subcribe-btn-container {
        .subcribe-btn {
          width: 50%;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .title {
      font-size: 26px;
    }

    .messages {
      font-size: 16px;
      line-height: 170%;
      margin: 0 50px;
  }
  .ant-form {
      margin-top: 20px;
      margin-bottom: 0px;
  }
  .logo {
      width: 240px;
      margin-bottom: 25px;
  }
  .ant-input-group .ant-input {
      height: 44px;
      font-size: 16px;
      padding:0 20px;
  }
  .subscribe_form_field .ant-btn.subcribe-btn {
      height: 44px;
      font-size: 16px;
  }
  .subscribe_form_field {
    max-width: 380px;
  } 
    .form-container {
      padding: 0 40px;

      .email-field {
        margin-right: 10px;
      }

      .subcribe-btn-container {
        .subcribe-btn {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 991px) and (max-width: 1024px) {
    padding: 10px 30px;

    .title {
      font-size: 20px;
    }

    .form-container {
      padding: 0 40px;

      .email-field {
        margin-right: 10px;
      }

      .subcribe-btn-container {
        .subcribe-btn {
          width: 100%;
        }
      }
    }
  }
`
export default WithDirection(BodyWraper);
