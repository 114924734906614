import styled from 'styled-components';
import WithDirection from '../../settings/withDirection';

const LoadingWrapper = styled.div`
  width: 100%;
  height: ${props => props.pageLoading ? '100vh' : '100%'};
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;

  .loader-element {
    height: 10em;
    width: 10em;
    ${'' /* animation: rotation 1s infinite linear;
    border: 2px solid rgba(51, 105, 231, 0.3);
    border-top-color: rgb(51, 105, 231);
    border-radius: 50%; */}
  }

  @keyframes rotation {
    to {
      transform: rotate(360deg);
    }
  }
`;

export default WithDirection(LoadingWrapper);