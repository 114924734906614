/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Row, Col, Button } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { get, chain, last } from "lodash";

import ModalEmailContact from "../../components/ModalEmailContact";

import NavWrapper from "./styles";

class Nav extends Component {
  constructor(props) {
    super(props);

    const pathName = get(props, "history.location.pathname", "");
    const checkPathArr = pathName.split("/");

    this.state = {
      isVisible: false,
      isToggle: false,
      isInvestorBrowser: false,
      isInvestorMobile: false,
      showThanksModal: false,
    };

    this.navOptions = [
      { path: "/", label: "HOME" },
      { path: "/about", label: "ABOUT" },
      { path: "/our-products", label: "PRODUCTS" },
      {
        path: "/overview",
        label: "INVESTORS",
        children: [
          { path: "/overview", label: "Overview" },
          { path: "/all-press-release", label: "All Press" },
          {
            path: `/all-press-release/${last(checkPathArr)}`,
            label: "All Press",
          },
          {
            path: "https://www.otcmarkets.com/stock/CLIS/disclosure",
            label: "Annual Reports",
            target: "_blank",
          },
          { path: "/sec-filings", label: "SEC Filings" },
          {
            path: "https://www.otcmarkets.com/stock/CLIS/disclosure",
            label: "Quarterly Filings",
            target: "_blank",
          },
          { path: "/ambassador", label: "AMBASSADOR PROGRAM" },
        ],
      },
      { path: "/contact", label: "CONTACT US" },
      // { path: "/customer-support", label: "CUSTOMER SUPPORT" },
    ];
    this.investorsChildrens = this.navOptions.filter(
      (ele) => ele.label === "INVESTORS"
    );
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const { history } = this.props;

    const path = get(history, "location.pathname", "");

    const isInvestorChild = chain(this.investorsChildrens[0].children)
      .map((child) => child.path)
      .includes(path)
      .value();

    if (window.innerWidth < 992) {
      return this.setState({
        isInvestorBrowser: false,
        isInvestorMobile: isInvestorChild,
      });
    }

    return this.setState({
      isInvestorMobile: false,
      isInvestorBrowser: isInvestorChild,
    });
  };

  handleOnToggle = () => {
    const { isToggle } = this.state;
    this.setState({ isToggle: !isToggle });
  };

  handleOnClickInvestorOnBrowser = () => {
    this.setState({ isInvestorBrowser: !this.state.isInvestorBrowser });
  };

  handleOnClickInvestorOnMobile = () => {
    this.setState({ isInvestorMobile: !this.state.isInvestorMobile });
  };

  onShowModal = () => {
    this.setState({ isVisible: true });
  };

  onCloseModal = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { isToggle, isInvestorBrowser, isInvestorMobile, isVisible } =
      this.state;
    const { history, isHomepage } = this.props;

    const pathname = isInvestorBrowser
      ? "/investors"
      : get(history, "location.pathname", "");
    const stylingInvestorBrowser = {
      color: isInvestorBrowser ? "#000000" : isHomepage ? "#b8ceff" : "#44444f",
      fontWeight: isInvestorBrowser ? "700" : isHomepage ? "600" : "600",
    };
    const stylingInvestorMobile = {
      color: isInvestorMobile ? "#000000" : "#44444f",
      fontWeight: isInvestorMobile ? "700" : "600",
    };
    return (
      <NavWrapper
        id="navbar"
        isToggle={isToggle}
        isInvestorBrowser={isInvestorBrowser}
        isHomepage={isHomepage}
      >
        <div className="navbar-container container">
          <Row type="flex" align="middle">
            <Col xs={10} sm={13} md={15} lg={5} className="logo-container">
              <a href="/">
                {isHomepage ? (
                  <img
                    className="logo"
                    src={`${process.env.PUBLIC_URL}/images/logo/white-logo.png`}
                    alt=""
                  />
                ) : (
                  <img
                    className="logo"
                    src={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                    alt=""
                  />
                )}
              </a>
            </Col>

            <Col xs={0} sm={0} md={0} lg={14} className="menu-container">
              <nav className="parent-nav">
                {this.navOptions.map((ele, id) => {
                  const activedColorNav = {
                    color: pathname === ele.path ? "#000000" : "#3d3d3d",
                    fontWeight: pathname === ele.path ? "bold" : "600",
                  };
                  const activedColorNavHomePage = {
                    color: pathname === ele.path ? "#ffffff" : "#b8ceff",
                  };
                  return (
                    <div key={id} style={{ display: "inline-block" }}>
                      {ele.label === "INVESTORS" ? (
                        <a
                          href={ele.path}
                          id="investor-label"
                          style={stylingInvestorBrowser}
                          onClick={this.handleOnClickInvestorOnBrowser}
                        >
                          {ele.label}
                        </a>
                      ) : (
                        <a
                          href={ele.path}
                          style={
                            isHomepage
                              ? activedColorNavHomePage
                              : activedColorNav
                          }
                        >
                          {ele.label}
                        </a>
                      )}
                    </div>
                  );
                })}
              </nav>
            </Col>

            <Col
              xs={9}
              sm={7}
              md={6}
              lg={5}
              className="get-btn-container text-right justify-content-end"
            >
              <Button
                type="primary"
                className="notified-btn"
                onClick={this.onShowModal}
              >
                GET NOTIFIED
              </Button>
            </Col>

            <Col xs={5} sm={3} md={3} lg={0} className="icon-container">
              <a href={null} className="icon" onClick={this.handleOnToggle}>
                {isToggle ? (
                  <span className="bar_toggle bar_toggle_show">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                ) : (
                  <span className="bar_toggle ">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                )}
              </a>
            </Col>
          </Row>

          <Row align="top" className="dropdown-container">
            {this.navOptions.map((ele, id) => {
              const iconCaret = isInvestorMobile ? (
                <CaretUpOutlined />
              ) : (
                <CaretDownOutlined />
              );

              return (
                <Col
                  key={id}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={0}
                  className="item-dropdown"
                >
                  {ele.label === "INVESTORS" ? (
                    <div>
                      <a
                        id="investor-label"
                        style={stylingInvestorMobile}
                        onClick={this.handleOnClickInvestorOnMobile}
                      >
                        {ele.label} {iconCaret}
                      </a>
                      {isInvestorMobile && (
                        <Row className="dropdown-investors-mobile">
                          {this.investorsChildrens[0].children.map(
                            (child, id) => {
                              const styling = {
                                color:
                                  get(history, "location.pathname", "") ===
                                  child.path
                                    ? "#000000"
                                    : "#44444f",
                                fontWeight:
                                  get(history, "location.pathname", "") ===
                                  child.path
                                    ? "700"
                                    : "500",
                              };

                              return child.label === "All Press" ? null : (
                                <Col
                                  key={id}
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={0}
                                  className="children-investor"
                                >
                                  <a
                                    target={child.target}
                                    href={child.path}
                                    style={styling}
                                    className="label-children"
                                  >
                                    {child.label.toUpperCase()}
                                  </a>
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      )}
                    </div>
                  ) : (
                    <a id="investor-label" key={id} href={ele.path}>
                      {ele.label}
                    </a>
                  )}
                </Col>
              );
            })}
          </Row>

          <Row align="middle" className="dropdown-investors-browser">
            {this.investorsChildrens[0].children.map((ele, id) => {
              const styling = {
                color:
                  get(history, "location.pathname", "") === ele.path
                    ? "#000000"
                    : "#44444f",
                fontWeight:
                  get(history, "location.pathname", "") === ele.path
                    ? "700"
                    : "500",
              };

              return ele.label === "All Press" ? null : (
                <a
                  key={id}
                  target={ele.target}
                  href={ele.path}
                  style={styling}
                  className="investor-item"
                >
                  {ele.label.toUpperCase()}
                </a>
              );
            })}
          </Row>
        </div>
        {isVisible && (
          <ModalEmailContact
            isHasForm={true}
            isVisible={isVisible}
            onCloseModal={this.onCloseModal}
            title="Stay informed about ClickStream"
            messages={`Get updates on new press releases, products and CLIS newsletters.`}
            showThanksModal={true}
          />
        )}
      </NavWrapper>
    );
  }
}

export default connect(
  (state) => ({
    collapsed: state.common.collapsed,
    isNoneNav: state.common.isNoneNav,
  }),
  (dispatch) => bindActionCreators({}, dispatch)
)(Nav);
